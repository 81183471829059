import React from 'react'
import { css } from 'styled-components'

import theme from '../../../../styles/theme'

import Button from '../../../Button'

import { palette, space } from '../../../../utils/tokens'

const CallToAction = (props) => {
  return (
    <section
      css={css`
        background-color: ${props => props.theme.colors.base};
        color: ${props => props.theme.colors.white};
        padding: ${theme.space[5]}px ${theme.space[4]}px;

        ${theme.mediaQueries.lg} {
          padding: ${theme.space[6]}px ${theme.space[7]}px;
        }
      `}
    >
      <div
        css={css`
          ${theme.mediaQueries.lg} {
            display: grid;
            grid-template-columns: 1fr 2fr;
            max-width: 1440px;
            margin: auto;
          }
        `}
      >
        <p css={css`
          ${theme.mediaQueries.lg} {
            margin-top: ${theme.space[5]}px;
          }
        `}>— hi there</p>
        <div>
          <h2
            css={css`
            font-size: ${theme.fontSizes[5]};
            color: ${theme.colors.white};

            ${theme.mediaQueries.lg} {
              font-size: ${theme.fontSizes[6]};
            }
          `}
          >Got an idea?</h2>
          <p
            css={css`
              line-height: 1.86;
              color: ${palette.grey[40]};
              margin-bottom: ${space[4]}px;
            `}
          >Got tons of new ideas or you just curiosity mind, and love to explore? Hit the button we are ready for collabs and new projects!</p>
          <Button
            path="/contact/"
          >Talk to us</Button>
        </div>
      </div>
    </section>
  )
}

export default CallToAction