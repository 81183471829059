/* eslint-disable react/display-name */
import React from 'react'
import Image from 'gatsby-image'
import { css } from 'styled-components'

import theme from '../../../../styles/theme'
import { fontFamily, palette, fontSizes } from '../../../../utils/tokens'

const ProblemSection = (props) => {
  const { title, htmlBody, image } = props
  return (
    <section
      css={css`
        display: flex;
        flex-direction: column-reverse;
        ${theme.mediaQueries.lg} {
          display: grid;
          grid-template-columns: 1fr 1fr;
          padding: ${theme.space[4]}px 0;
        }

        ${theme.mediaQueries.xxl} {
          height: 80vh;
        }
      `}
    >
      <Image fluid={image.fluid} alt={image.title} />
      <div
        css={css`
          padding: 0 ${theme.space[6]}px 0 ${theme.space[5]}px;
        `}
      >
        <h5
          css={css`
            margin-bottom: ${theme.space[5]}px;
            font-weight: 600;
            color: ${theme.colors.black}b4;
            letter-spacing: 2px;
          `}
        >THE PROBLEM</h5>
        <h2
          css={css`
            font-size: 2.5rem;
            color: ${props => props.theme.colors.black};
            margin-bottom: ${theme.space[3]}px;
          `}
        >{title}</h2>
        <div
          css={css`
            color: ${palette.grey[80]};
            line-height: 1.8;
            font-size: ${fontSizes[2]};

            p {
              font-family: ${fontFamily.body};
              margin-bottom: ${props => props.theme.space[3]}px;
            }
          `}
          dangerouslySetInnerHTML={{ __html: htmlBody }}
        />
      </div>
    </section>
  )
}
export default ProblemSection