import React, { useState} from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { css } from 'styled-components'
import config from '../utils/siteConfig'

import Layout from '../components/Layout'

import Container from '../components/Container'
import Hero from '../components/portfolio-post/components/hero'
import { ImageList } from '../components/Gallery'
import SEO from '../components/SEO'
import PostLinks from '../components/PostLinks'
import { TitleWithLineH2 } from '../components/TitleWithLine'

import ProblemSection from '../components/portfolio-post/components/sections/problem-section'
import SolutionSection from '../components/portfolio-post/components/sections/solution-section'
import CallToAction from '../components/portfolio-post/components/sections/call-to-action'
import { SectionSelector } from '../components/SectionSelector'

import { space, mediaQueries } from '../utils/tokens'

const PortfolioTemplate = ({ data }) => {
  const [isLightboxOpen, setLightBoxOpen] = useState(false)
  const [currentImageIndex, setCurrentImage] = useState(0)
  const [count, setCount] = useState(12)

  const toggleLightBox = (index) => {
    setCurrentImage(index)
    setLightBoxOpen(!isLightboxOpen)
  }

  const {
    title,
    shortDescription,
    coverPhoto,
    slug,
    id,
    gallery,
    companyLogo,
    problemSectionBody,
    problemSectionTitle,
    problemSectionImage,
    solutionSectionTitle,
    solutionSectionBody,
    sections
  } = data.contentfulPortfolioWork

  const postNode = {
    title: `${title} - ${config.siteTitle}`,
    metaDescription: { internal: { content: shortDescription } },
  }


  const postIndex = data.allContentfulPortfolioWork.edges.find(
    ({ node: post }) => post.id === id
  )

  return (
    <div>
      <Layout>
        <Helmet>
          <title>{`${data.contentfulPortfolioWork.title} | ${config.siteTitle}`}</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <SEO
          postNode={postNode}
          pagePath={`portfolio/${slug}`}
          customTitle
          pageSEO
        />
        <div
          css={css({
            paddingTop: `${space[6]}px`,
            [mediaQueries.lg]: {
              paddingTop: 0,
            }
          })}
        >
          <Hero
            title={title}
            image={coverPhoto}
            subTitle={shortDescription}
            logo={companyLogo}
          />
        </div>

        {sections && <SectionSelector sections={sections} />}
        {problemSectionTitle && problemSectionBody && problemSectionImage && (
          <ProblemSection
            title={problemSectionTitle}
            htmlBody={problemSectionBody.childMarkdownRemark.html}
            image={problemSectionImage}
          />
        )}
        {solutionSectionTitle && solutionSectionBody && (
          <SolutionSection
            title={solutionSectionTitle}
            bodyRichText={solutionSectionBody}
          />
        )}
        {gallery && (
          <section
            css={css`
              background-color: #f8f8f8;
              padding: ${space[5]}px ${space[4]}px;

              ${mediaQueries.xxl} {
                padding: ${space[6]}px ${space[5]}px;
              }
            `}
          >
            <Container>
              <TitleWithLineH2>The Results</TitleWithLineH2>
              {gallery && (
                <>
                  <ImageList
                    images={gallery}
                    onButtonClick={() => setCount(count + 12)}
                    onImageClick={index => toggleLightBox(index)}
                  />
                </>
              )}
            </Container>
          </section>
        )}
        <CallToAction />
        <div
          css={css({
            padding: `${space[4]}px`,
            display: `flex`,
            justifyContent: `center`,
          })}
        >
          <PostLinks
            previous={postIndex.previous && postIndex.previous}
            next={postIndex.next && postIndex.next}
            css={css({
              width: `100%`,
            })}
          />
        </div>
      </Layout>
    </div>
  )
}

export default PortfolioTemplate

export const portfolioTemplateQuery = graphql`
  query PortfolioTemplateQuery($slug: String!) {
    contentfulPortfolioWork(slug: { eq: $slug }) {
      title
      id
      shortDescription
      slug
      companyLogo {
        title
        file {
          url
        }
      }
      sections {
        ... on Node {
          ...SectionsFragment
        }
      }
      problemSectionTitle
      problemSectionImage {
        title
        fluid(maxWidth: 3000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      problemSectionBody {
        childMarkdownRemark {
          html
        }
      }
      solutionSectionTitle
      solutionSectionBody {
        raw
      }
      gallery {
        title
        id
        fixed(quality: 100 width: 300) {
          ...GatsbyContentfulFixed_noBase64
        }
        thumbnail: fixed(width: 100) {
          src
        }
        fluid(maxWidth: 3000) {
          src
        }
        fullscreen: fluid(maxWidth: 3000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      coverPhoto {
        title
        fluid(maxWidth: 3000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    allContentfulPortfolioWork(
      limit: 1000
    ) {
      edges {
        node {
          id
          title
        }
        previous {
          title
          coverPhoto {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          fields {
            path
          }
        }
        next {
          title
          coverPhoto {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          fields {
            path
          }
        }
      }
    }
  }
`