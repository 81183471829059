/* eslint-disable react/display-name */
import React from 'react'
import styled, { css } from 'styled-components'
import { BLOCKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import theme from '../../../../styles/theme'

const ItemTitle = styled.h5`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: ${theme.space[3]}px;
  margin-top: ${theme.space[4]}px;
`

const List = styled.ul`
  list-style: none;
  margin: 0;

  ${theme.mediaQueries.lg} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${theme.space[4]}px;
  }
`

const Item = styled.li`
  font-size: ${theme.fontSizes[2]};
  color: ${theme.colors.black};
  letter-spacing: 0;
  line-height: 28px;
  padding-top: ${theme.space[4]}px;
  padding-bottom: ${theme.space[5]}px;
  padding-left: ${theme.space[3]}px;
  padding-right: ${theme.space[3]}px;
  border-top: solid 1px ${theme.colors.base};
  margin-bottom: 0;
  transition-property: all;
  transition-duration: 300ms;

  &:nth-child(odd) {
    background-color: ${theme.colors.base};
    color: ${theme.colors.white};

    h5 {
      color: ${theme.colors.white};
    }
  }

  ${theme.mediaQueries.lg} {
    padding-left: ${theme.space[4]}px;
    padding-right: ${theme.space[4]}px;
  }
`

const options = {
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,
    [BLOCKS.LIST_ITEM]: (node, children) => <Item>{children}</Item>,
    [BLOCKS.HEADING_5]: (node, children) => <ItemTitle>{children}</ItemTitle>
  }
}

const SolutionSection = (props) => {
  const { bodyRichText, title } = props

  return (
    <section
      css={css`
        padding: ${theme.space[5]}px ${theme.space[4]}px;

        ${theme.mediaQueries.lg} {
          max-width: 1440px;
          margin: auto;
          padding-top: ${theme.space[6]}px;
          padding-left: ${theme.space[6]}px;
          padding-right: ${theme.space[6]}px;
        }
      `}
    >
      <h2
        css={css`
          font-weight: bold;
          font-size: ${theme.fontSizes[4]};

          ${theme.mediaQueries.lg} {
            margin-bottom: ${theme.space[5]}px;
            max-width: 33%;
          }
        `}
      >{title}</h2>
      <div
        css={css`
        ${theme.mediaQueries.lg} {
          display: grid;
          grid-template-columns: 1fr 2fr;
        }
      `}
      >
        <h3
          css={css`
          font-weight: bold;
          font-size: ${theme.fontSizes[1]};
          color: ${theme.colors.black}b4;
          letter-spacing: 2px;
          margin-top: ${theme.space[4]}px;
          text-align: center;
          ${theme.mediaQueries.lg} {
            text-align: left;
            margin-left: ${theme.space[5]}px;
          }
        `}
        >THE SOLUTION</h3>
        <div>
          {renderRichText(bodyRichText, options)}
        </div>
      </div>
    </section>
  )
}
  
export default SolutionSection