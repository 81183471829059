import React from 'react'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

import theme from '../../../styles/theme'
import { space } from '../../../utils/tokens'

const TitleGroup = styled.div`
  text-align: center;
  color: ${theme.colors.base};
  margin-bottom: ${theme.space[5]}px;
  padding-left: ${theme.space[4]}px;
  padding-right: ${theme.space[4]}px;

  ${theme.mediaQueries.lg} {
    margin-top: ${theme.space[5]}px;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
`
const Title = styled.h1`
  font-size: ${theme.fontSizes[5]};
  font-weight: bold;
  margin-bottom: ${theme.space[3]}px;
  color: ${theme.colors.base};
  text-align: center;
  line-height: 1;
`

const Hero = (props) => {
  const { title, logo, subTitle, image } = props
  return (
    <section
      css={css`
        padding-top: ${theme.space[3]}px;

        ${theme.mediaQueries.lg} {
          padding-bottom: ${theme.space[6]}px;
        }
      `}
    >
      <TitleGroup>
        {logo &&
          <img
            alt={logo.title}
            src={logo.file.url}
            css={css`
              max-height: 64px;
              width: auto;
              margin: auto;
              margin-bottom: ${space[3]}px;
            `}
          />}
        <Title>{title}</Title>
        <p>{subTitle}</p>
      </TitleGroup>
      <div css={css`
        max-width: 1440px;
        margin: auto;

        ${theme.mediaQueries.lg} {
          padding-left: ${theme.space[5]}px;
          padding-right: ${theme.space[5]}px;
        }
      `}>
        <Img
          fluid={image.fluid}
          alt={image.title}
          css={css`
            margin-bottom: 0;
            ${theme.mediaQueries.lg} {
              > :first-child {
                padding-bottom: 42.55% !important;
              }
            }
        `}
        />
      </div>
    </section>
  )
}

export default Hero