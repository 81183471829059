import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Gallery from "react-photo-gallery";
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import Lightbox from 'react-image-lightbox'

import Button from '../../components/Button'
import { media } from '../../utils/media';
import { mediaQueries } from '../../utils/tokens';

const ImageListRoot = styled.main`
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: auto;
  .grid {
    margin: 0 auto;
  }
  .grid-item {
    margin-bottom: 16px;
  }

  ${media.ultrahd`
    max-width: 1440px;
  `}
`;


const ImageList = ({ images, count, onButtonClick, onImageClick }) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const shownImages = images.slice(0, count)

  const imagesWithSizes = shownImages.map((image) => {
    return {
      fluid: image.thumbnail,
      width: image.thumbnail.aspectRatio,
      height: 1,
      src: image.lightBoxImage?.src,
      key: image.id,
      title: image.title,
    }
  })

  return (
    <ImageListRoot>
      <Gallery
        photos={imagesWithSizes}
        direction="column"
        renderImage={({ index, left, top, key, photo, onClick }) => {
          return (
            <Photo
              key={photo.key}
              index={index}
              photo={photo}
              left={left}
              top={top}
              margin={16}
              onClick={onClick}
            />
          )
        }}
        onClick={openLightbox}
      />
      {count < images.length && (
        <Button
          onClick={onButtonClick}
          css={css`
            margin: 2rem auto;
          `}
        >
          Load more
        </Button>
      )}
      {viewerIsOpen ? (
        <Lightbox
          mainSrc={imagesWithSizes[currentImage].src}
          nextSrc={
            imagesWithSizes[(currentImage + 1) % imagesWithSizes.length].src
          }
          imageTitle={imagesWithSizes[currentImage].title}
          prevSrc={
            imagesWithSizes[
              (currentImage + imagesWithSizes.length - 1) %
                imagesWithSizes.length
            ].src
          }
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setCurrentImage(
              (currentImage + imagesWithSizes.length - 1) %
                imagesWithSizes.length
            )
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % imagesWithSizes.length)
          }
          imagePadding={64}
          enableZoom={false}
        />
      ) : null}
    </ImageListRoot>
  )
}

const Photo = ({ photo, margin, index, top, left, onClick }) => {
  const handleClick = event => {
    onClick(event, { photo, index })
  }

  return (
    <figure
      onClick={handleClick}
      index={index}
      css={{
        top: top,
        left: left,
        margin: `${margin}px`,
        cursor: `pointer`,
        [mediaQueries.md]: {
          position: `absolute`,
          overflow: `hidden`,
          width: photo.width,
          height: photo.height,
        },
      }}
    >
      <Img fluid={photo.fluid} alt={photo.title} />
    </figure>
  )
}

ImageList.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    fluid: PropTypes.object,
    title: PropTypes.string,
  })).isRequired,
  onImageClick: PropTypes.func,
}

export default ImageList
