import React from 'react'
import styled, { css } from 'styled-components'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'

import { media } from '../utils/media'

import theme from '../styles/theme'
import { mediaQueries } from '../utils/tokens'

const Wrapper = styled.aside`
  display: grid;
  grid-auto-rows: 100px;
  grid-gap: 1rem;
  padding: ${theme.space[4]}px calc(${theme.space[3]}px + ${theme.space[2]}px);
  ${mediaQueries.lg} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 150px;
    grid-gap: 2rem;
    padding: 0 ${theme.space[3]}px;
    width: 75%;
  }
  a {
    text-decoration: none;
  }
`

const PreviousLink = styled(Link)`
  ${media.desktop`
    grid-column-start: 1;
  `}
`

const NextLink = styled(Link)`
  ${media.desktop`
    grid-column-start: 2;
  `}
`

const CoverImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  max-height: 300px;
  border-radius: 10px;
  ${media.tablet`
      max-height: none;
    `
  }
  height: ${props => props.height};
  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'inherit'};
  }
  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
  }
  &::before {
    content: '';
    background-image: linear-gradient(225deg, rgba(245,166,35,0.30) 0%, #001839 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  color: ${props => props.theme.colors.white};
`

const LinkText = styled.p`
  text-align: ${props => props.isPrevious ? 'left' : 'right'};
  width: 100%;
  margin-bottom: 0;
`

const PreviousCard = ({ previous }) => {
  return (
    <div css={css`
      position: relative;
      height: 100%;
      width: 100%;
    `}>
    {
      previous.coverPhoto &&
        <CoverImg
          fluid={previous.coverPhoto.fluid}
          alt={previous.coverPhoto.title}
        />
    }
      <TextContainer>
        <FaArrowLeft
          css={css`
            margin-right: 0.5rem;
          `}
        />
        <LinkText isPrevious>{previous.title}</LinkText>
      </TextContainer>
    </div>
  )
}

const NextCard = ({ next }) => {
  return (
    <div css={css`
      position: relative;
      height: 100%;
      width: 100%;
    `}>
    {
      next.coverPhoto &&
        <CoverImg
          fluid={next.coverPhoto.fluid}
          alt={next.coverPhoto.title}
        />
    }
      <TextContainer>
        <LinkText>{next.title}</LinkText>
        <FaArrowRight
          css={css`
            margin-left: 0.5rem;
          `}
        />
      </TextContainer>
    </div>
  )
}

const PostLinks = props => {
  const { previous, next } = props
  return (
    <Wrapper className={props.className}>
      {props.previous && (
        <PreviousLink to={previous.fields.path}>
          <PreviousCard
            previous={previous}
          />
        </PreviousLink>
      )}
      {props.next && (
        <NextLink to={next.fields.path}>
          <NextCard
            next={next}
          />
        </NextLink>
      )}
    </Wrapper>
  )
}

export default PostLinks
